import * as React from 'react';

import Layout from '../containers/Layout';
import useTranslation from '../hooks/useTranslation';
import Seo from '../components/Seo';
import Heading from '../components/Heading';

function Homepage({ pageContext: { currentPath } }) {
  const { translations, locale } = useTranslation();

  return (
    <Layout background="full">
      <Seo location={currentPath} lang={locale} />

      <div className="flex items-end h-full max-w-xl mx-auto">
        <div className="pt-2 px-8 pb-8 md:p-8 text-center backdrop-filter backdrop-blur-sm">
          <Heading level="h2" visualLevel="h5" className="space-y-4">
            {translations.HOMEPAGE.TITLE.map((text, index) => (
              <span className="block" key={index}>
                {text}
              </span>
            ))}
          </Heading>
        </div>
      </div>
    </Layout>
  );
}

export default Homepage;
